// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { json, Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useNavigate, useRouteError } from "@remix-run/react";
import { Button, Card, NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import styles from "./tailwind.css";
import SettingsController from "./controllers/SettingsController";
import { toast, Toaster } from "sonner";
import { useEffect } from "react";
import { commitFlashSession, getFlashSession } from "./utils/flash-session";
import moment from "moment";
import { ArrowLeftIcon } from "./components/icons/ArrowLeft";
export const links = () => [{
  rel: "stylesheet",
  href: styles
}];
export default function App() {
  _s();
  const {
    theme,
    generalSettings,
    message
  } = useLoaderData();
  useEffect(() => {
    if (message) {
      if (message.status == "error") {
        toast.error(message.title, {
          description: `${moment().format("dddd, MMMM D, YYYY [at] h:mm A")} ${message.description ? " - " + message.description : ""}`,
          cancel: {
            label: "Cancel",
            onClick: () => console.log("Close!")
          },
          unstyled: false,
          classNames: {
            // toast: "bg-blue-400",
            title: "text-red-500",
            // description: "text-red-400",
            // actionButton: "bg-zinc-400",
            cancelButton: "bg-red-400"
            // closeButton: "bg-lime-400",
          }
        });
      } else if (message.status == "success") {
        toast.success(message.title, {
          description: `${moment().format("dddd, MMMM D, YYYY [at] h:mm A")} ${message.description ? " - " + message.description : ""}`,
          cancel: {
            label: "Cancel",
            onClick: () => console.log("Close!")
          },
          unstyled: false,
          classNames: {
            // toast: "bg-blue-400",
            title: "text-green-600",
            // description: "text-red-400",
            // actionButton: "bg-zinc-400",
            cancelButton: "bg-red-400"
            // closeButton: "bg-lime-400",
          }
        });
      }
    }
  }, [message]);
  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className={`text-sm ${theme?.name}`}>
        <NextUIProvider>
          <NextThemesProvider
        // themes={["aseda", "dark", "light"]}
        attribute="class" defaultTheme="light">

            <Outlet />
            <Toaster />
            <ScrollRestoration />
            <Scripts />
            <LiveReload />
          </NextThemesProvider>
        </NextUIProvider>
      </body>
    </html>;
}
_s(App, "0uB1cvxAbNKV1gM/T0eWLtRg7A8=", false, function () {
  return [useLoaderData];
});
_c = App;
export const loader = async ({
  request
}) => {
  const settingsController = await new SettingsController(request);
  const {
    theme
  } = await settingsController.getThemesSettings();
  const generalSettings = await settingsController.getGeneralSettings();
  const session = await getFlashSession(request.headers.get("Cookie"));
  const message = session.get("message") || null;
  return json({
    message,
    theme,
    generalSettings
  }, {
    headers: {
      // only necessary with cookieSessionStorage
      "Set-Cookie": await commitFlashSession(session)
    }
  });
};
export function ErrorBoundary() {
  _s2();
  const navigate = useNavigate();
  const error = useRouteError();
  // console.error(error);
  return <html lang="en">
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>

      <body className="bg-gray-100 dark:bg-content1 min-h-screen flex flex-col items-center justify-center">
        <Card className="bg-white p-12 rounded-lg shadow-lg max-w-md w-full text-center">
          <h1 className="text-5xl font-bold mb-4 text-gray-800">404</h1>
          <p className="text-lg text-gray-600 mb-8">
            Oops! The page you're looking for isn't here.
          </p>

          <Button startContent={<ArrowLeftIcon className="size-5" />} color="primary" onClick={() => navigate(-1)}>

            Go Back
          </Button>
        </Card>

        <Scripts />
      </body>
    </html>;
}
_s2(ErrorBoundary, "F/M1g+qWQ3QcVY/Zmtirt0oa37c=", false, function () {
  return [useNavigate, useRouteError];
});
_c2 = ErrorBoundary;
var _c, _c2;
$RefreshReg$(_c, "App");
$RefreshReg$(_c2, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;